<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-3">
        <v-menu
          v-model="dateCurrentFromPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateCurrentFrom"
              label="Date From (Current)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateCurrentFromProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-menu
          v-model="dateCurrentToPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateCurrentTo"
              label="Date To (Current)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateCurrentToProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-menu
          v-model="datePastFromPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datePastFrom"
              label="Date From (Past)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datePastFromProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-menu
          v-model="datePastToPick"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datePastTo"
              label="Date To (Past)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datePastToProto"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="dateCurrentFrom && dateCurrentTo">
      <v-col>
        <v-btn @click="buildReport">
          Build Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <h4>Current</h4>
        <v-simple-table>
          <thead>
            <tr>
              <th>Job Type</th>
              <th>Number of DRS</th>
              <th>Total Mileage</th>
              <th>Materials</th>
              <th>Total Costs</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Totals</th>
              <th>{{ totals.count }}</th>
              <th>{{ totals.mileage | numeral('0,0' ) }}</th>
              <th>{{ totals.materials | currency }}</th>
              <th>{{ totals.costs | currency }}</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(stat, index) in stats" :key="`s_${index}`">
              <td>{{ stat.job_type }}</td>
              <td>{{ stat.total_drs }}</td>
              <td>{{ stat.total_mileage | numeral('0,0') }}</td>
              <td>{{ stat.materials | currency }}</td>
              <td>{{ stat.total_costs | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col class="col-6">
        <h4>Past</h4>
        <v-simple-table>
          <thead>
            <tr>
              <th>Job Type</th>
              <th>Number of DRS</th>
              <th>Total Mileage</th>
              <th>Materials</th>
              <th>Total Costs</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Totals</th>
              <th>{{ oldTotals.count }}</th>
              <th>{{ oldTotals.mileage | numeral('0,0' ) }}</th>
              <th>{{ oldTotals.materials | currency }}</th>
              <th>{{ oldTotals.costs | currency }}</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(stat, index) in oldStats" :key="`s_${index}`">
              <td>{{ stat.job_type }}</td>
              <td>{{ stat.total_drs }}</td>
              <td>{{ stat.total_mileage | numeral('0,0') }}</td>
              <td>{{ stat.materials | currency }}</td>
              <td>{{ stat.total_costs | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'DrsJobTypeReport',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      dateCurrentFrom: null,
      dateCurrentFromProto: null,
      dateCurrentFromPick: false,
      dateCurrentTo: null,
      dateCurrentToProto: null,
      dateCurrentToPick: false,
      datePastFrom: null,
      datePastFromProto: null,
      datePastFromPick: false,
      datePastTo: null,
      datePastToProto: null,
      datePastToPick: false,
      stats: [],
      totals: {},
      oldStats: [],
      oldTotals: {},
    };
  },
  watch: {
    dateCurrentFromProto () {
      this.dateCurrentFrom = this.formatDate(this.dateCurrentFromProto);
      this.datePastFrom = this.formatDate(this.subtractYear(this.dateCurrentFromProto));
    },
    dateCurrentToProto () {
      this.dateCurrentTo = this.formatDate(this.dateCurrentToProto);
      this.datePastTo = this.formatDate(this.subtractYear(this.dateCurrentToProto));
    },
    datePastFromProto () {
      this.datePastFrom = this.formatDate(this.datePastFromProto);
    },
    datePastToProto () {
      this.datePastTo = this.formatDate(this.datePastToProto);
    },
  },
  methods: {
    buildReport() {
      const postData = {};
      postData.dateCurrentFrom = this.dateCurrentFrom;
      postData.dateCurrentTo = this.dateCurrentTo;
      postData.datePastFrom = this.datePastFrom;
      postData.datePastTo = this.datePastTo;
      axios.post(`/dailyReturnSheets/jobTypeReport.json?token=${this.token}`, postData)
        .then((response) => {
          this.stats = response.data.stats;
          this.totals = response.data.totals[0];
          this.oldStats = response.data.oldStats;
          this.oldTotals = response.data.oldTotals[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    subtractYear(dt) {
      const date = new Date(dt);
      const year = date.getFullYear();
      const yearAgo = (year - 1);
      const dateAgo = date.setFullYear(yearAgo);
      return format(new Date(dateAgo), 'yyy-MM-dd');
    },
  },
};
</script>